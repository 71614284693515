<template>
  <v-container fluid class="pt-0 fill-height align-start">
    <v-row justify="center" class="fill-height" style="align-content: start">
      <v-col cols="12" md="12" class="pb-2" style="height: 64px">
        <v-toolbar elevation="2" class="pa-0" height="52">
          <v-toolbar-title class="py-2">
            Створення нового норматива
          </v-toolbar-title>
          <v-spacer/>
          <YouTubeButton
              button-class="grey lighten-4"
              icon-color="error lighten-1"
              :icon-size="26"
              button-style="height: 42px; width: 42px; margin-right: 3px;"
              href="https://youtu.be/gU5RWMrFfe8"
          />
        </v-toolbar>
      </v-col>
      <v-col cols="6" md="6" :style="$vuetify.breakpoint.mdAndUp ? 'height: 92vh;' : ''">
        <v-card class="fill-height">
          <v-list-item-group
              style="
                    height: 100%;
                    overflow-y: auto;
                    background-color: #ffffff;
                    border-left: 3px solid #fafafa;
                    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
                  "
          >
            <v-list class="py-0">
              <v-list-item
                  link
                  v-for="(item, idx) in serviceList" :key="`temp-serv-${idx}`"
                  @click.stop="fillNormative(item)"
                  style="border-bottom: 1px solid #f5f5f5"
              >
                <v-list-item-icon>
                  <v-icon size="32" :color="getAccountIcon(item.icon, 'color')">{{
                      getAccountIcon(item.icon, 'icon')
                    }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ getRegularDetails(item) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-list-item-group>
        </v-card>
      </v-col>
      <v-col cols="6" md="6" :style="$vuetify.breakpoint.mdAndUp ? 'height: 92vh;' : ''">
        <v-card class="fill-height">
          <v-form v-model="formValid" class="fill-height" ref="form">
            <v-row class="ma-0 py-3 fill-height" style="align-content: flex-start; overflow: auto">
              <v-col cols="8">
                <v-text-field type="text" hide-details filled label="Назва повна"
                              v-model="regular.name"
                              required :rules="[v => !!v || 'Це поле є обов’язковим']"
                              :class="regular.name ? '' : 'req-star'"
                              color="grey"
                />
              </v-col>
              <v-col cols="4">
                <v-checkbox
                    v-model="regular.social_normative"
                    inset
                    hide-details
                    color="success"
                    label="Соціальний норматив"
                />
              </v-col>
              <v-col cols="12" v-if="regular.social_normative">
                <v-select :items="social_calc_type_select" filled label="Вид розрахунку пільги"
                          hide-details
                          v-model="regular.social_calc_type"
                          color="grey"
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="regular.social_calc_type ? '' : 'req-star'"
                />
              </v-col>
              <v-col cols="12">
                <v-select :items="services" filled label="Послуга"
                          hide-details
                          color="grey"
                          v-model="regular.service_id"
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="regular.service_id ? '' : 'req-star'"
                />
              </v-col>
              <v-col cols="12">
                <v-switch
                    v-model="regular.only_square"
                    inset
                    color="success"
                    hide-details
                    class="mt-1"
                    :label="regular.only_square ? 'Норма по площі (включена)' : 'Норма по площі (виключена)'"
                />
              </v-col>
              <v-col cols="12" md="12" v-if="regular.only_square">
                <v-select :items="indicators_select" hide-details filled
                          label="Вид показника проживаючих/зареєстрованих"
                          v-model="regular.indicator_id_person"
                          required
                          color="grey"
                          :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="regular.indicator_id_person ? '' : 'req-star'"
                />
              </v-col>
              <v-col cols="12" md="12" v-if="regular.only_square">
                <v-select :items="indicators_select" hide-details filled label="Вид показника площі"
                          v-model="regular.indicator_id_square"
                          required
                          color="grey"
                          :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="regular.indicator_id_square ? '' : 'req-star'"
                />
              </v-col>
              <v-col cols="6" v-if="regular.only_square">
                <v-text-field type="text" hide-details filled label="Норма площі на 1 людину"
                              v-model.number="regular.square_on_person"
                              color="grey"
                              v-decimal
                />
              </v-col>
              <v-col cols="6" v-if="regular.only_square">
                <v-text-field type="text" hide-details filled label="Норма площі на сім’ю"
                              v-model.number="regular.square_on_family"
                              v-decimal
                              color="grey"
                />
              </v-col>
              <v-col cols="6" v-if="regular.only_square">
                <v-text-field type="text" hide-details filled label="Норма на метр квадратний (з буд.ліч)"
                              v-model.number="regular.param_on_square_counter"
                              v-decimal color="grey"
                />
              </v-col>
              <v-col cols="6" v-if="regular.only_square">
                <v-text-field type="text" hide-details filled label="Норма на метр квадратний (без буд.ліч)"
                              v-model.number="regular.param_on_square_without"
                              v-decimal color="grey"
                />
              </v-col>
              <v-col cols="12" v-if="regular.only_square">
                <v-switch
                    v-model="regular.not_over_square"
                    inset
                    hide-details
                    class="mt-1" color="success"
                    :label="regular.not_over_square ? 'Норма в межах обраної площі' : 'Норма не залежить від обраної площі'"
                />
              </v-col>
              <v-col cols="12">
                <v-switch
                    v-model="regular.only_person"
                    inset
                    hide-details
                    class="mt-1" color="success"
                    :label="regular.only_person ? 'Норма на людину (включена)' : 'Норма на людину (виключена)'"
                />
              </v-col>
              <v-col cols="12" md="12" v-if="regular.only_person">
                <v-select :items="indicators_select" hide-details filled
                          label="Вид показника проживаючих/зареєстрованих"
                          v-model="regular.indicator_id_person"
                          required color="grey"
                          :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="regular.indicator_id_person ? '' : 'req-star'"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field type="text" hide-details filled label="Норма на 1 людину"
                              v-if="regular.only_person"
                              v-model.number="regular.param_on_person"
                              v-decimal color="grey"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field type="text" hide-details filled label="Норма на сім’ю"
                              v-if="regular.only_person"
                              v-model.number="regular.param_on_family"
                              v-decimal color="grey"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field type="text" hide-details filled label="Максимальна норма"
                              v-if="regular.only_person"
                              v-model.number="regular.param_max_value"
                              v-decimal color="grey"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field type="text" hide-details filled label="Мінімальна к-сть чоловік у сім’ї"
                              v-if="regular.only_person"
                              v-model.number="regular.param_on_person_min_count"
                              v-decimal color="grey"
                />
              </v-col>
              <v-col cols="12" style="justify-self: flex-end">
                <v-btn depressed block color="grey lighten-4" class="mt-4" @click.stop="createService">
                  <v-icon left>
                    mdi-save
                  </v-icon>
                  Зберегти
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  calculateMethodsSelect,
  accountIconSelect,
  getAccountIcon,
  accountIcons,
  getRegularDetails,
  social_calc_type_select
} from "@/utils/icons";
import {mapGetters, mapActions} from "vuex";
import {FETCH_FLAT_INDICATOR_TYPES} from "@/store/actions/flat_indicators";
import {CREATE_REGULARS} from "@/store/actions/services";
import {ALERT_SHOW} from "@/store/actions/alert";

export default {
  name: "ServiceCreate",
  components: {
    YouTubeButton: () => import('@/components/YouTubeButton')
  },
  computed: {
    ...mapGetters({
      indicators_select: 'getFlatIndicatorTypes',
      services: 'getServicesSelect',
      legacy: 'isLegacy'
    })
  },
  data() {
    return {
      formValid: false,
      calculateMethodsSelect,
      accountIconSelect,
      accountIcons,
      social_calc_type_select,
      serviceList: [
        {
          icon: 'house_pay',
          name: 'Утримання будинку, норматив на домогосподарство',
          square_on_person: 10.5,
          square_on_family: 21.0,
          param_on_square_counter: 0,
          param_on_square_without: 0,
          param_on_person: 0,
          param_on_family: 0,
          param_on_person_min_count: 0,
          param_max_value: 0,
          not_over_square: true,
          only_square: true,
          only_person: false
        },
        {
          icon: 'water_cold',
          name: 'Холодна вода на 1 особу з центр. гар. водопостачанням',
          square_on_person: 0,
          square_on_family: 0,
          param_on_square_counter: 0,
          param_on_square_without: 0,
          param_on_person: 2.0,
          param_on_family: 0,
          param_on_person_min_count: 0,
          param_max_value: 0,
          not_over_square: false,
          only_square: false,
          only_person: true
        },
        {
          icon: 'water_cold',
          name: 'Холодна вода на 1 особу без центр. гар. водопостачанням',
          square_on_person: 0,
          square_on_family: 0,
          param_on_square_counter: 0,
          param_on_square_without: 0,
          param_on_person: 3.6,
          param_on_family: 0,
          param_on_person_min_count: 0,
          param_max_value: 0,
          not_over_square: false,
          only_square: false,
          only_person: true
        },
        {
          icon: 'water_hot',
          name: 'Гараяча вода на 1 особу',
          square_on_person: 0,
          square_on_family: 0,
          param_on_square_counter: 0,
          param_on_square_without: 0,
          param_on_person: 1.6,
          param_on_family: 0,
          param_on_person_min_count: 0,
          param_max_value: 0,
          not_over_square: false,
          only_square: false,
          only_person: true
        },
        {
          icon: 'sewerage',
          name: 'Водовідведення на 1 особу',
          square_on_person: 0,
          square_on_family: 0,
          param_on_square_counter: 0,
          param_on_square_without: 0,
          param_on_person: 3.6,
          param_on_family: 0,
          param_on_person_min_count: 0,
          param_max_value: 0,
          not_over_square: false,
          only_square: false,
          only_person: true
        },
        {
          icon: 'trash',
          name: 'Поводження з твердими, побутовими відходами на 1 особу',
          square_on_person: 0,
          square_on_family: 0,
          param_on_square_counter: 0,
          param_on_square_without: 0,
          param_on_person: 0.4167,
          param_on_family: 0,
          param_on_person_min_count: 0,
          param_max_value: 0,
          not_over_square: false,
          only_square: false,
          only_person: true
        },
        {
          icon: 'liquid_sewage',
          name: 'Поводження з рідкими відходами на 1 особу',
          square_on_person: 0,
          square_on_family: 0,
          param_on_square_counter: 0,
          param_on_square_without: 0,
          param_on_person: 0.76,
          param_on_family: 0,
          param_on_person_min_count: 0,
          param_max_value: 0,
          not_over_square: false,
          only_square: false,
          only_person: true
        },
        {
          icon: 'liquid_sewage',
          name: 'Вивезення рідких нечистот на 1 домогосподарство',
          square_on_person: 0,
          square_on_family: 0,
          param_on_square_counter: 0,
          param_on_square_without: 0,
          param_on_person: 3.6,
          param_on_family: 0,
          param_on_person_min_count: 0,
          param_max_value: 0,
          not_over_square: false,
          only_square: false,
          only_person: true
        },
        {
          icon: 'gas',
          name: 'Газова плита та центр. гар. водопостач.',
          square_on_person: 0,
          square_on_family: 0,
          param_on_square_counter: 0,
          param_on_square_without: 0,
          param_on_person: 3.3,
          param_on_family: 0,
          param_on_person_min_count: 0,
          param_max_value: 0,
          not_over_square: false,
          only_square: false,
          only_person: true
        },
        {
          icon: 'gas',
          name: 'Газова плита та без центр. гар. водопостач.',
          square_on_person: 0,
          square_on_family: 0,
          param_on_square_counter: 0,
          param_on_square_without: 0,
          param_on_person: 5.4,
          param_on_family: 0,
          param_on_person_min_count: 0,
          param_max_value: 0,
          not_over_square: false,
          only_square: false,
          only_person: true
        },
        {
          icon: 'gas',
          name: 'Газова плита та газовий водонагрівача',
          square_on_person: 0,
          square_on_family: 0,
          param_on_square_counter: 0,
          param_on_square_without: 0,
          param_on_person: 10.5,
          param_on_family: 0,
          param_on_person_min_count: 0,
          param_max_value: 0,
          not_over_square: false,
          only_square: false,
          only_person: true
        },
        {
          icon: 'electro',
          name: 'Електронергія. Без електроплит та центр. холод. водопостачання',
          regular_type: 'regular_volume_by_1_person',
          square_on_person: 0,
          square_on_family: 0,
          param_on_square_counter: 0,
          param_on_square_without: 0,
          param_on_person: 30.0,
          param_on_family: 70.0,
          param_on_person_min_count: 1,
          param_max_value: 190.0,
          not_over_square: false,
          only_square: false,
          only_person: true
        },
        {
          icon: 'electro',
          name: 'Електронергія. Електроплита та центр. гар. водопостачання',
          regular_type: 'regular_volume_by_1_person',
          square_on_person: 0,
          square_on_family: 0,
          param_on_square_counter: 0,
          param_on_square_without: 0,
          param_on_person: 30.0,
          param_on_family: 110.0,
          param_on_person_min_count: 1,
          param_max_value: 230.0,
          not_over_square: false,
          only_square: false,
          only_person: true
        },
        {
          icon: 'electro',
          name: 'Електронергія. Електроплита та без центр. гар. водопостачання',
          regular_type: 'regular_volume_by_1_person',
          square_on_person: 0,
          square_on_family: 0,
          param_on_square_counter: 0,
          param_on_square_without: 0,
          param_on_person: 30.0,
          param_on_family: 130.0,
          param_on_person_min_count: 1,
          param_max_value: 250.0,
          not_over_square: false,
          only_square: false,
          only_person: true
        },
        {
          icon: 'electro',
          name: 'Електронергія. Без електроплит, з холод. водопостач. та без гар. водопостач.',
          square_on_person: 0,
          square_on_family: 0,
          param_on_square_counter: 0,
          param_on_square_without: 0,
          param_on_square: 0,
          param_on_person: 30.0,
          param_on_family: 100.0,
          param_on_person_min_count: 1,
          param_max_value: 220.0,
          not_over_square: false,
          only_square: false,
          only_person: true
        },
        {
          icon: 'heating',
          name: 'Нормативний об’єм споживання м.кв',
          regular_type: 'regular_volume_by_square_heating',
          square_on_person: 0,
          square_on_family: 0,
          param_on_square_counter: 0.0431,
          param_on_square_without: 0,
          param_on_person: 0,
          param_on_family: 0,
          param_on_person_min_count: 0,
          param_max_value: 0,
          not_over_square: false,
          only_square: false,
          only_person: true
        },
      ],
      regular: {
        name: '',
        indicator_id_square: null,
        indicator_id_person: null,
        service_id: null,
        square_on_person: 0,
        square_on_family: 0,
        param_on_square_counter: 0,
        param_on_square_without: 0,
        param_on_person: 0,
        param_on_family: 0,
        param_on_person_min_count: 0,
        param_max_value: 0,
        not_over_square: 0,
        only_square: false,
        only_person: false,
        social_normative: false,
        social_calc_type: null
      }
    }
  },
  methods: {
    ...mapActions({
      fetchIndicators: FETCH_FLAT_INDICATOR_TYPES
    }),
    getRegularDetails,
    getAccountIcon,
    fillNormative(item) {
      if (!item) {
        return undefined
      }

      this.regular.name = item.name
      this.regular.indicator_id_square = item.indicator_id_square ? item.indicator_id_square : null
      this.regular.indicator_id_person = item.indicator_id_person ? item.indicator_id_person : null
      this.regular.service_id = item.service_id
      this.regular.square_on_person = item.square_on_person
      this.regular.square_on_family = item.square_on_family
      this.regular.param_on_square_counter = item.param_on_square_counter
      this.regular.param_on_square_without = item.param_on_square_without
      this.regular.param_on_person = item.param_on_person
      this.regular.param_on_family = item.param_on_family
      this.regular.param_on_person_min_count = item.param_on_person_min_count
      this.regular.param_max_value = item.param_max_value
      this.regular.not_over_square = item.not_over_square
      this.regular.only_square = item.only_square
      this.regular.only_person = item.only_person
    },
    createService() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Усі обов’язкові поля повинні бути заповнені',
          color: 'error lighten-1'
        })
        return undefined
      }

      const payload = Object.assign({is_legacy: this.legacy}, this.regular)

      this.$store.dispatch(CREATE_REGULARS, payload)
          .then(el => {
            if (el) {
              this.$router.push({ name: 'Services', query: { tab: 1 } })
            }
          })
    }
  },
  async created() {
    await this.fetchIndicators()
  },
}
</script>

<style scoped>

</style>